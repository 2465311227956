.profile-container {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  height: 10%;
  min-height: fit-content;
  padding: 10px 0 10px 0;
  max-width: 1280px ;
}
.profile-parent {
  display: flex;
  align-items: center;
  color: aliceblue;
}

.profile-details-name {
  font-size: 24px;
  font-family: "Poppins SemiBold";
}
.profile-details-role {
  display: flex;
  flex-direction: column;
  margin: 14px 0 24px 0;
  max-width: 800px;
  padding-left: 10px;
}
.profile-details-role h1 {
  font-size: 40px;
  font-family: cursive;
}
.primary-text {
  color: (--white);
}
.highlighted-text {
  color: #1f2235;
}
.profile-role-tagline {
  font-size: 19px;
  margin: 5px 0 0 0;
  font-family: "Poppins Light";
}
.profile-options .highlighted-btn {
  margin: 0 0 0 28px;
}
.profile-options .highlighted-btn:hover {
  margin: 0 0 0 28px;
  background-color: cornsilk;
  color: #111;
}
.profile-picture {
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 1px 0px 0px white;
  border-radius: 50%;
  height: 380px;
  width: 380px;
}
.profile-picture-background {
  
  height: 100%;
  width: 100%;
  background-image: url("../../../assets/Home/me.jpeg");
  background-size: cover;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
}

.profile-options {
  display: flex;
  justify-content: space-evenly;
}
.colz .colz-icon i {
  color: white;
  padding: 8px;
  padding-left: 0px;
  font-size: 30px;
}

.colz .colz-icon i:hover {
  transform: scale(1.5);
}

@media screen and (max-width: 1110px) {
  .profile-picture {
    margin: 0 0px 0 80px;
  }
  .profile-details-name {
    font-size: 20px;
  }
  .profile-details-role .primary-text {
    font-size: 12px;
  }

  .profile-options .btn {
    width: 120px;
    padding: 11px 0;
    z-index: 700;
  }
}

@media only screen and (max-width: 910px) {
  .profile-picture {
    margin: 0 0 0 4px;
    height: 320px;
    width: 320px;
  }
}
@media only screen and (max-width: 810px) {
  .profile-picture {
    margin: 0 !important;
  }
  .profile-parent {
    flex-direction: column;
    margin: 40px 0 0 0;
  }
  .profile-options {
    display: flex;
    justify-content: space-evenly;
  }
  .proflie-details {
    margin: 25px 0 0;
  }
  .profile-options .highlighted-btn {
    margin: 0 !important;
  }
  .profile-details-name,
  .profile-details-role {
    text-align: center;
  }
}

@media only screen and (max-width: 375px) {
  .profile-picture {
    height: 275px;
    width: 275px;
    margin-top: -90px;
  }
  .proflie-details {
    width: 95%;
  }
}
