.header-container {
    display: flex;
    justify-content: center;
    height: 149px;
    color: var(--white);
    background-color: #1f2235;
    border-bottom: #1f2235;
    min-width: 100%;
  }
  .header-parent {
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 100%;
    width: 70%;
    color: var(--white);
  }
  .header-logo {
    font-size: 50px;
    margin-right: 50px;
    font-family: "Poppins ExtraBold";
    color: var(--white);
  }
  .header-options {
    display: flex;
    align-items: center;
  }
  .header-option {
    font-size: 16px;
    cursor: pointer;
    font-weight: 600;
  }
  .header-hamburger {
    display: none;
    color: var(--white);
  }
  .header-option-seperator {
    margin: 0 50px 0 0;
    color: var(--white);
  }
  .selected-header-option {
    color: gray;
  }
  
  /*IPAD PRO RESPONSIVENESS*/
  @media only screen and (max-width: 1110px) {
    header-parent {
      width: 80%;
    }
  }
  @media only screen and (max-width: 970px) {
    .header-hamburger {
      display: block;
    }
    .header-parent {
      width: 100%;
  
      position: relative;
    }
    .header-options {
      display: flex;
      flex-direction: column;
      position: absolute;
      left: -100%;
      transition: all 0.8s ease-out;
      top: 0px;
      height: 100vh;
      width: 100%;
      justify-content: space-around;
      background-color: #1f2235;
      z-index: 1000;
      opacity: 0.5;
    }
    .header-option-seperator {
      margin: 0;
    }
    .selected-header-option {
      color: var(--white);
    }
    .header-hamburger-bars {
      font-size: 36px;
      cursor: pointer;
      margin: 0px 10px;
      color: var(--white);
    }
    .show-hamburger-options {
      left: 0;
      opacity: 1;
    }
  }
  @media only screen and (max-width: 430px) {
   .header-logo{
    font-size: 30px;
   }
  }