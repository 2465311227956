.main-container {
  /* background-image: url("../../assets/ContactMe/contact_us.jpeg"); */

  margin: 30px 0 0 0;
}

.central-form {
  max-width: 1100px;
  padding: 10px;
  display: flex;
  box-shadow: 0 0px 20px -2px #1f2235;
  border-radius: 12px;
  margin: 0 auto;
  flex-direction: column;
  justify-content: center;
  background-color: #1f2235;
}

.central-form h2 {
  color: #fff;
  font-family: "Poppins Bold";
  letter-spacing: 0.2rem;
  margin-bottom: 20px;
}


.col{
  display: flex;
  flex-direction:column;
  align-items: center;

}
.email{
  color:white;
  
}
.col i {
  font-size: 50px;
  padding: 7px;
  color: #fff;
}
.col i:hover {
  transform: scale(1.5);
}

@media screen and (min-width: 940px) {
  .back-form {
    max-width: 1100px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
  }

  .img-back {
    flex: 1;
    align-self: center;
    margin-right: 20px;
  }

  form {
    flex: 0.9;
  }
}
@media screen and (max-width: 414px) {
  .central-form h2 {
    font-size: 16px;
  }
}
